export function getParams(query, key) {
  return new URLSearchParams(query).get(key)
}

export function blogCreatedAtFormat(createdAt) {
  const splitCreatedAt = createdAt?.split(" ")
  const checkDay = Number(splitCreatedAt?.[1]) === 3 ? "rd" : "th"

  return `${splitCreatedAt?.[0]} ${splitCreatedAt?.[1]}${checkDay} ${splitCreatedAt?.[2]}`
}

export function filterDummyBlog(nodes) {
  return nodes.filter(({ id }) => id !== "4e4c6129-e5d9-570f-b54d-2346154ba3b2")
}

export function capitalizeWords(str) {
  return str.replace(/\b\w/g, char => char.toUpperCase());
}